import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import {Helmet} from "react-helmet";

import './index.css';

import GridList from '../GridList';
import Map from '../Map';
import Story from '../Story';
import * as API from '../../constants/apiEndpoints';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stories: {},
      filteredStories: {},

      regions: [],
      themes: [],

      hoveredStory: {},
      selectedStory: {},
      
      selectedRegion: 'All regions',
      selectedTheme: 'All themes',
    }
  }

  componentDidMount() {
    axios.get(API.STORIES)
      .then((response) => {

        // get regions and themes
        let regions = [];
        let themes = [];
        for (let i = 0; i < response.data.features.length; i += 1) {
          let region = response.data.features[i].properties.region;
          regions.push(...region);

          let theme = response.data.features[i].properties.theme;
          themes.push(...theme);
        }
        regions = Array.from(new Set(regions));

        themes = Array.from(new Set(themes));
        
        // alphabetize
        themes.sort();
        
        // move Climate Impacts and Climate Solutions to the top
        for (let i = 0; i < themes.length; i += 1) {
          let theme = themes[i];
          if (theme === 'Climate Impacts' || theme === 'Climate Solutions' || theme === 'Solutions: Adaptation' || theme === 'Solutions: Drawdown GA') {
            themes.splice(i, 1); 
            i -= 1;
          }
        }
        themes = ['Climate Impacts', 'Solutions: Adaptation', 'Solutions: Drawdown GA',  ...themes];

        this.setState({
          stories: response.data,
          filteredStories: response.data,
          regions: ['All regions', ...regions],
          themes: ['All themes', ...themes],
        }, () => {
          return true;
        })
      })
      .then((response) => {
        // if there is a slug, load that story
        let _slug = this.props.match.params.id;
        let selectedStory = {}
        if (_slug !== undefined) {
          selectedStory = this.getStory(_slug);
        }

        this.setState({
          selectedStory: selectedStory,
        }, () => {
          return true;
        })
      })
      .then((response) => {
        const values = queryString.parse(this.props.location.search);

        let region = 'All regions';
        if ('region' in values) {
          region = values.region;
        }

        let theme = 'All themes';
        if ('theme' in values) {
          theme = values.theme;
        }

        this.filter({
          region: region,
          theme: theme,
        });

        return true;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if there's a slug, select the story
    let previousSlug = prevProps.match.params.id;
    let currentSlug = this.props.match.params.id;
    if (previousSlug !== currentSlug) {
      if (currentSlug === undefined) {
        this.setState({
          selectedStory: {},
        })
      } else {
        // TODO - refactor this lookup
        let story = this.getStory(currentSlug);
        this.setState({
          selectedStory: story,
          hoveredStory: {},
        });  
      }
    }

    // if there's a query param, select the filter
    let previousQueryParams = prevProps.location.search;
    let currentQueryParams = this.props.location.search;
    if (previousQueryParams !== currentQueryParams) {
      const values = queryString.parse(this.props.location.search);

      let region = 'All regions';
      if ('region' in values) {
        region = values.region;
      }

      let theme = 'All themes';
      if ('theme' in values) {
        theme = values.theme;
      }

      this.filter({
        region: region,
        theme: theme,
      });      
    }
  }

  getStory = (slug) => {
    // TODO - refactor this lookup
    for (let i = 0; i < this.state.filteredStories.features.length; i += 1) {
      let story = this.state.filteredStories.features[i];
      if (slug === story.properties._slug) {
        return story
      }
    }

    return {};
  }

  onHoverStory = (story) => {
    this.setState({
      hoveredStory: story,
    })
  }

  filter = (params) => {
    let allStories = this.state.stories;

    let selectedRegion = params.region;
    let selectedTheme = params.theme;

    let features = []
    if (selectedRegion === 'All regions' && selectedTheme === 'All themes') {
      for (let i = 0; i < allStories.features.length; i += 1) {
        let story = allStories.features[i];
        features.push(story);
      }
    } else if (selectedRegion !== 'All regions' && selectedTheme === 'All themes') {
      for (let i = 0; i < allStories.features.length; i += 1) {
        let story = allStories.features[i];
        if (story.properties.region.includes(selectedRegion)) {
          features.push(story);
        }
      }
    } else if (selectedRegion === 'All regions' && selectedTheme !== 'All themes') {
      for (let i = 0; i < allStories.features.length; i += 1) {
        let story = allStories.features[i];
        if (story.properties.theme.includes(selectedTheme)) {
          features.push(story);
        }
      }
    } else {
      for (let i = 0; i < allStories.features.length; i += 1) {
        let story = allStories.features[i];
        if (story.properties.region.includes(selectedRegion) && story.properties.theme.includes(selectedTheme)) {
          features.push(story);
        }
      }
    }

    this.setState({
      selectedRegion: selectedRegion,
      selectedTheme: selectedTheme,
      filteredStories: {
        type: 'FeatureCollection',
        features: features,
      }
    })
  }



  render() {
    let hasSelectedStory = Object.keys(this.state.selectedStory).length > 0;

    let clear = <div></div>;
    if (this.state.selectedRegion !== 'All regions' || this.state.selectedTheme !== 'All themes') {
      clear = <div className="clear">
        <Button className="clear" variant="link" onClick={() => {
          this.props.history.push(`${this.props.location.pathname}`);
        }}>Show all</Button>
      </div>;
    }

    let controls = <div></div>;
    if (!hasSelectedStory) {
      controls = <div className="controls filters">
        <div className="container-fluid"> 
          <Dropdown className="filter" onSelect={(e) => {
            this.filter({
              region: e,
              theme: this.state.selectedTheme,
            });

            if (e === 'All regions' && this.state.selectedTheme === 'All themes') {
              this.props.history.push(`${this.props.location.pathname}`);  
            } else if (e === 'All regions' && this.state.selectedRegion !== 'All thmes') {
              this.props.history.push(`${this.props.location.pathname}?theme=${this.state.selectedTheme}`);  
            } else if (e !== 'All regions' && this.state.selectedTheme === 'All themes') {
              this.props.history.push(`${this.props.location.pathname}?region=${e}`);
            } else {
              this.props.history.push(`${this.props.location.pathname}?region=${e}&theme=${this.state.selectedTheme}`);
            }
            
          }}>
            <Dropdown.Toggle variant="white">{this.state.selectedRegion}</Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.regions.map((region, index) => (
                <Dropdown.Item key={index} eventKey={region}>{region}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="filter" onSelect={(e) => {
            this.filter({
              region: this.state.selectedRegion,
              theme: e,
            });

            if (e === 'All themes' && this.state.selectedRegion === 'All regions') {
              this.props.history.push(`${this.props.location.pathname}`);  
            } else if (e === 'All themes' && this.state.selectedRegion !== 'All regions') {
              this.props.history.push(`${this.props.location.pathname}?region=${this.state.selectedRegion}`);  
            } else if (e !== 'All themes' && this.state.selectedRegion === 'All regions') {
              this.props.history.push(`${this.props.location.pathname}?theme=${e}`);
            } else {
              this.props.history.push(`${this.props.location.pathname}?region=${this.state.selectedRegion}&theme=${e}`);
            }

          }}>
            <Dropdown.Toggle variant="white">{this.state.selectedTheme}</Dropdown.Toggle>
            <Dropdown.Menu>
              {this.state.themes.map((theme, index) => (
                <Dropdown.Item key={index} eventKey={theme}>{theme}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          { clear }

        </div>         
      </div>
    }

    let leftContent;
    if (hasSelectedStory) {
      leftContent = <Story
        stories={this.state.filteredStories}
        story={this.state.selectedStory}
        {...this.props}
      />
    } else {
      leftContent = <GridList 
        stories={this.state.filteredStories}
        {...this.props}
        
        onHoverStory={this.onHoverStory}
      />
    }

    return (
      <div className="main">
        <Helmet>
          <title>Georgia Climate Stories | Georgia Climate Project</title>

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://stories.georgiaclimateproject.org/" />
          <meta property="og:title" content="Georgia Climate Stories" />
          <meta property="og:image" content="https://www.georgiaclimateproject.org/static/img/logo.png" />
          <meta property="og:image:width" content="658" />
          <meta property="og:image:height" content="345" />
          <meta property="og:site_name" content="Georgia Climate Project" />
          <meta property="og:description" content="Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts." />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Georgia Climate Stories" />
          <meta name="twitter:description" content="Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts." />
          <meta name="twitter:image" content="https://www.georgiaclimateproject.org/static/img/logo.png" />

          <meta name="description" content="Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts." />
        </Helmet>

        <div className="left">

          <header className="header">
            <div className="container-fluid">
              <div className="header-left">
                <div className="logo">
                  <a href="https://www.georgiaclimateproject.org" aria-label="home"><img src="https://www.georgiaclimateproject.org/wp-content/uploads/2020/02/logo.png" alt="Georgia Climate Project logo" /></a>
                </div>
                <div className="secondary-logo">
                  <div onClick={() => {
                    this.props.history.push(`/`);
                  }} className="initiative">Georgia Climate Stories</div>
                </div>
              </div>
              <div className="header-right">
                <ul className="nav">
                  <li><a href="/about" aria-label="about">About</a></li>
                </ul>
              </div>
            </div>
          </header>
          { controls }

          { leftContent }
        </div>
        <div className="right">
          <Map
            stories={this.state.filteredStories}
            {...this.props}

            hoveredStory={this.state.hoveredStory}
            selectedStory={this.state.selectedStory}
            onSelectStory={this.onSelectStory}
          />
        </div>
      </div>
    );
  }
}
        
export default App;