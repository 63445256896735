import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import './index.css';

class About extends Component {
  render() {
    return (
      <div className="about">
        <Helmet>
          <title>About | Georgia Climate Stories</title>

          <meta property="og:type" content="website" />
          <meta property="og:title" content="About | Georgia Climate Stories" />
          <meta property="og:image" content="https://www.georgiaclimateproject.org/static/img/logo.png" />
          <meta property="og:image:width" content="658" />
          <meta property="og:image:height" content="345" />
          <meta property="og:site_name" content="Georgia Climate Project" />
          <meta property="og:description" content="Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts." />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Georgia Climate Stories" />
          <meta name="twitter:description" content="Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts." />
          <meta name="twitter:image" content="https://www.georgiaclimateproject.org/static/img/logo.png" />

          <meta name="description" content="Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts." />
        </Helmet>

        <header className="header">
          <div className="container-fluid">
            <div className="header-left">
              <div className="logo">
                <a href="https://www.georgiaclimateproject.org" aria-label="home"><img src="https://i0.wp.com/www.georgiaclimateproject.org/wp-content/uploads/2020/02/logo.png" alt="Georgia Climate Project logo" /></a>
              </div>
              <div className="secondary-logo">
                <a href="/" aria-label="Georgia Climate Project">
                  <div className="initiative">Georgia Climate Stories</div>
                </a>
              </div>
            </div>
            <div className="header-right">
            </div>
          </div>
        </header>

        <div className="section">
          <div className="container">
            <h1 className="title center">Highlighting personal stories of climate impacts and solutions in Georgia</h1>
            <p>Georgia Climate Stories is an initiative to highlight the personal stories of Georgians whose lives and livelihoods are impacted by climate change and those who are taking practical steps to respond to climate change and its impacts.</p>
            <p>In addition to content generated by the Georgia Climate Project, this site features stories produced by several other organizations. Many thanks to these organizations who have given us permission to spotlight their content on this site.</p>
            <p>Have a great idea for a Georgia Climate Story? Please let us know!</p>
            <div style={{lineHeight: '45px', margin: '21px 0 0 0'}}>
              <a style={{marginRight: '28px'}} className="gcp-button" target="_blank" rel="noopener noreferrer" href="https://goo.gl/forms/RxcHHgg17yAhYC7y2">Submit an idea</a>
              <a className="gcp" target="_blank" rel="noopener noreferrer" href="mailto:GeorgiaClimateProject@emory.edu?Subject=Georgia%20Climate%20Stories">Contact us <i className="fas fa-chevron-right"></i></a>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="social">
            <div className="container-fluid">
              <h5>Connect with us</h5>
              <ul>
                <li><a href="https://twitter.com/GAClimateProj" title="Twitter" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                <li><a href="https://www.facebook.com/georgiaclimateproject" target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook"><i className="fab fa-facebook"></i></a></li>
                <li><a href="https://instagram.com/georgiaclimateproject/" title="Instagram" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="activities">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3">
                  <h5>Science</h5>
                  <ul>
                    <li><a href="https://roadmap.georgiaclimateproject.org/" title="Georgia Climate Research Roadmap" aria-label="Georgia Climate Research Roadmap">Georgia Climate Research Roadmap</a></li>
                    <li className="coming-soon" data-toggle="tooltip" data-placement="left" title="Coming soon">Georgia Climate Information Portal</li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h5>Stronger Conversations</h5>
                  <ul>
                    <li><a href="https://stories.georgiaclimateproject.org/" title="Georgia Climate Stories" aria-label="Georgia Climate Stories">Georgia Climate Stories</a></li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h5>Solutions</h5>
                  <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.drawdownga.org/" aria-label="Drawdown Georgia">Georgia Drawdown</a></li>
                    <li><a href="https://carbonreduction.gatech.edu/" title="Carbon Reduction Challenge" aria-label="Carbon Reduction Challenge" target="_blank" rel="noopener noreferrer">Carbon Reduction Challenge</a></li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h5>Stronger Network</h5>
                  <ul>
                    <li><a title="Georgia Climate Conference" aria-label="Georgia Climate Conference" href="https://conference.georgiaclimateproject.org/">Georgia Climate Conference</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="brand">
            <div className="container-fluid">
              <div className="logo">
                <a href="https://www.georgiaclimateproject.org" aria-label="Georgia Climate Project home"><img src="https://i0.wp.com/www.georgiaclimateproject.org/wp-content/uploads/2020/02/logo.png" alt="Georgia Climate Project logo" /></a>
              </div>
              
              <div className="links">
                <ul>
                  <li><a title="About Georgia Climate Project" aria-label="About" href="https://www.georgiaclimateproject.org/about">About Georgia Climate Project</a></li>
                  <li><a title="Contact us" aria-label="Contact us" href="mailto:GeorgiaClimateProject@emory.edu">Contact us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>     
    )
  }
}

export default About