import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';

import './index.css';
import georgia from './georgia.json';

import markerIcon from './map-marker-alt-solid.svg';
import circleIcon from './circle-solid.svg';

// const orangeIcon = new L.icon({
//   iconUrl: require('./map-marker-alt-solid.svg'),
//   iconSize: [36, 36],
//   iconAnchor: [18, 32],
// });


class Map extends Component {
  componentDidMount() {
    mapboxgl.accessToken = 'pk.eyJ1IjoibHBvbGVwZWRkaSIsImEiOiJjamZ0MWoyeW8wM2RiMnhqczZmcW96czJjIn0.JNK2f_SXWAQWBuwIwpVzZg';

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-streets-v9',
      center: [-83.375244, 32.640157],
      zoom: 6.4,
    });

    this.map.on('load', () => {
      this.map.addSource('georgia', {
        'type': 'geojson',
        'data': georgia,
      })

      this.map.addLayer({
        "id": "state-border",
        'type': 'line',
        'source': 'georgia',
        'paint': {
          'line-color': '#ffffff',
          'line-width': 3,
        }
      })
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.circleMarkers = [];
    this.mapMarker = null;
  }

  onSelectStory = (story) => {
    this.props.onSelectStory(story)
  }

  render() {
    if (this.map) {
      // clear all circles and markers, and reset the map
      if (this.mapMarker) {
        this.mapMarker.remove();
        this.mapMarker = null;
      }

      if (this.circleMarkers.length > 0) {
        for (let i = 0; i < this.circleMarkers.length; i += 1) {
          this.circleMarkers[i].remove();
        }
        this.circleMarkers = [];
      }

      this.map.flyTo({
        bearing: 0,
        center: [-83.375244, 32.640157],
        zoom: 6.4,
        pitch: 0,
        speed: 0.9,
      })

      // if there's a hovered story, only add a marker
      let hasHoveredStory = Object.keys(this.props.hoveredStory).length > 0;
      if (hasHoveredStory) {
        // place orange marker
        let el = document.createElement('div')
        el.style.backgroundImage = 'url(' + markerIcon + ')';
        el.style.backgroundRepeat = 'no-repeat';
        el.style.width = '36px';
        el.style.height = '36px';
        this.mapMarker = new mapboxgl.Marker(el)
          .setLngLat(this.props.hoveredStory.geometry.coordinates)
          .addTo(this.map);
      }

      // if there is a selected story, add a marker and fly to it
      let hasSelectedStory = Object.keys(this.props.selectedStory).length > 0;
      if (hasSelectedStory) {
        // place orange marker
        let el = document.createElement('div')
        el.style.backgroundImage = 'url(' + markerIcon + ')';
        el.style.backgroundRepeat = 'no-repeat';
        el.style.width = '36px';
        el.style.height = '36px';
        this.mapMarker = new mapboxgl.Marker(el)
          .setLngLat(this.props.selectedStory.geometry.coordinates)
          .addTo(this.map);

        this.map.flyTo({
          bearing: this.props.selectedStory.properties.location.bearing,
          center: [this.props.selectedStory.properties.location.lng, this.props.selectedStory.properties.location.lat],
          zoom: this.props.selectedStory.properties.location.zoom,
          pitch: this.props.selectedStory.properties.location.pitch,
          speed: 0.9,
        })
      }

      // add circles for everything else
      if (!hasHoveredStory) {
        let geojsonFeatures = this.props.stories.features;
        geojsonFeatures.forEach((story) => {
          if (hasSelectedStory && this.props.selectedStory.properties.title === story.properties.title) {
            return;
          }

          let el = document.createElement('div')
          el.style.backgroundImage = 'url(' + circleIcon + ')';
          el.style.backgroundRepeat = 'no-repeat';
          el.style.width = '20px';
          el.style.height = '20px';

          let popupHTML = `
            <div class="popup card" style="background: url(${story.properties.img}) no-repeat center center; background-size: cover;">
              <div class="card-body">
                <div>
                  <p class="name">${story.properties.name}</p>
                  <p class="location"><i class="material-icons">room</i> ${story.properties.location.name}</p>
                </div>
              </div>
            </div>
          `;

          let circleMarker = new mapboxgl.Marker(el)
            .setLngLat(story.geometry.coordinates)
            .setPopup(new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: true,
              offset: 16,
            }).setHTML(popupHTML))
            .addTo(this.map);
          this.circleMarkers.push(circleMarker);

          el.addEventListener('mouseenter', (event) => { 
            circleMarker.togglePopup(); 
          });

          el.addEventListener('mouseleave', (event) => {
            circleMarker.togglePopup(); 
          });
          
          el.addEventListener('click', (event) => { 
            circleMarker.togglePopup();
            this.props.history.push(`/${story.properties._slug}${this.props.location.search}`);
          });
        })
      }
    }

    return (
      <div id="map"></div>
    );
  }
}

export default Map;