import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import './index.css';

class GridList extends Component {
  onHoverStart = (story) => {
    this.props.onHoverStory(story);
  }

  onHoverEnd = (story) => {
    this.props.onHoverStory({});
  }

  render() {
    let cards = [];

    if ('features' in this.props.stories) {
      if (this.props.stories.features.length > 0) {
        for (let i = 0; i < this.props.stories.features.length; i += 1) {
          let story = this.props.stories.features[i];

          let card = <Col xs={6} key={i}>
            <Link to={`/${story.properties._slug}${this.props.location.search}`}>
              <Card
                onMouseEnter={(e) => { this.onHoverStart(story) }}
                onMouseLeave={(e) => { this.onHoverEnd(story) }}
              >
                <Card.Body style={{ 
                  background: `url(${story.properties.img}) center center / cover no-repeat`,
                }}>
                  <div>
                    <p className="name" dangerouslySetInnerHTML={{__html: story.properties.name}}></p>
                    <p className="location"><i className="material-icons">room</i> {story.properties.location.name}</p>
                    <p className="publication">{story.properties.publication}</p>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          </Col>;

          cards.push(card);
        }
      } else {
        let card = <Col xs={12}>
          <Card>
            <p>No climate stories found. Try different or more general filters</p>
          </Card>
        </Col>

        cards.push(card);
      }
    }

    return (
      <div id="grid-list">
        <Row>
          {cards.length === 0 &&
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <p>Loading ...</p>
                </div>
              </div>
            </div>
            
          }
          { cards }
          
        </Row>
      </div>
    );
  }
}

export default GridList;