import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import About from './components/About';

const routes = (
  <Router>
    <Switch>
      <Route exact path='/2019-photo-contest' component={() => { 
        window.location.href = 'https://www.georgiaclimateproject.org/news/2019-student-photo-contest'; 
        return null;
      }}/>

      <Route path="/about" component={About} />
      <Route path="/:id" component={App} />
      <Route path="/" component={App} />
      
      
    </Switch>
  </Router>
)

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
