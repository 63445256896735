import React, { Component } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Helmet} from "react-helmet";
import queryString from 'query-string';
import {
  Link,
} from "react-router-dom";

import './index.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";

const getVideoEmbedUrl = function(videoUrl) {
  let videoEmbedUrl = '';

  if (videoUrl.includes('vimeo')) {
    let videoId = videoUrl.replace('https://vimeo.com/', '')
    videoEmbedUrl = `https://player.vimeo.com/video/${videoId}`
  }

  if (videoUrl.includes('youtube')) {
    let videoId = videoUrl.replace('https://www.youtube.com/watch?v=', '').replace('&', '?')
    videoEmbedUrl = `https://www.youtube.com/embed/${videoId}`
  }
  
  return videoEmbedUrl;
}

class Story extends Component {
  constructor(props) {
    super(props);

    this.current = 1;
  }

  componentDidMount() {
    document.addEventListener("keydown", (event) => {
      if (event.keyCode === 37) {
        let i = this.current - 1;
        if (i > 0) {
          let prevStory = this.props.stories.features[i-1]
          this.props.history.push(`/${prevStory.properties._slug}${this.props.location.search}`);
        }
      }

      if (event.keyCode === 39) {
        if (this.current < this.props.stories.features.length) {
          let i = this.current - 1;
          let nextStory = this.props.stories.features[i+1]
          this.props.history.push(`/${nextStory.properties._slug}${this.props.location.search}`);
        }
      }
    });
  }

  render() {
    const queryParams = queryString.parse(this.props.location.search);
    let queryParamString = this.props.stories.features.length;

    if (this.props.stories.features.length > 1) {
      queryParamString += ' stories'
    } else {
      queryParamString += ' story'
    }

    if (Object.keys(queryParams).length > 0) {
      if ('theme' in queryParams) {
        queryParamString += ` about ${queryParams.theme}`;
      }

      if ('region' in queryParams) {
        queryParamString += ` in ${queryParams.region}`;
      }
    }

    let playlist;
    let numStories = this.props.stories.features.length;
    this.current = 1;
    for (let i = 0; i < numStories; i += 1) {
      if (this.props.story.properties.name === this.props.stories.features[i].properties.name) {
        this.current = i+1;
        break;
      }
    }

    if (this.current === 1 && numStories === 1) {
      playlist = <div className="playlist">
        <div>
          <p className="queryParamString">{queryParamString}</p>
        </div>
        <div>
          <Button 
            variant="link"
            disabled
          >
            <i className="material-icons">chevron_left</i>
          </Button>
          {this.current} / {this.props.stories.features.length}
          <Button 
            variant="link"
            disabled
          >
            <i className="material-icons">chevron_right</i>
          </Button>
        </div>
      </div>
    } else if (this.current === 1) {
      playlist = <div className="playlist">
        <div>
          <p className="queryParamString">{queryParamString}</p>
        </div>
        <div>
          <Button 
            variant="link"
            disabled
          >
            <i className="material-icons">chevron_left</i>
          </Button>
          {this.current} / {this.props.stories.features.length}
          <Button 
            variant="link"
            onClick={() => {
              let i = this.current - 1;
              let nextStory = this.props.stories.features[i+1]
              this.props.history.push(`/${nextStory.properties._slug}${this.props.location.search}`);
            }}
          >
            <i className="material-icons">chevron_right</i>
          </Button>
        </div>
      </div>
    } else if (this.current === numStories) {
      playlist = <div className="playlist">
        <div>
          <p className="queryParamString">{queryParamString}</p>
        </div>
        <div>
          <Button 
            variant="link"
            onClick={() => {
              let i = this.current - 1;
              let previousStory = this.props.stories.features[i-1]
              this.props.history.push(`/${previousStory.properties._slug}${this.props.location.search}`);
            }}
          >
            <i className="material-icons">chevron_left</i>
          </Button>
          {this.current} / {this.props.stories.features.length}
          <Button 
            variant="link"
            disabled
          >
            <i className="material-icons">chevron_right</i>
          </Button>
        </div>
      </div>
    } else {
      playlist = <div className="playlist">
        <div>
          <p className="queryParamString">{queryParamString}</p>
        </div>
        <div>
          <Button 
            variant="link"
            onClick={() => {
              let i = this.current - 1;
              let previousStory = this.props.stories.features[i-1]
              this.props.history.push(`/${previousStory.properties._slug}${this.props.location.search}`);
            }}
          >
            <i className="material-icons">chevron_left</i>
          </Button>
          {this.current} / {this.props.stories.features.length}
          <Button 
            variant="link"
            onClick={() => {
              let i = this.current - 1;
              let nextStory = this.props.stories.features[i+1]
              this.props.history.push(`/${nextStory.properties._slug}${this.props.location.search}`);
            }}
          >
            <i className="material-icons">chevron_right</i>
          </Button>
        </div>
      </div>
    }

    let description = this.props.story.properties.description.replace('\n\n', '<br><br>');

    let link;
    let videoEmbedUrl = '';
    if (this.props.story.properties.video) {
      videoEmbedUrl = getVideoEmbedUrl(this.props.story.properties.video)

      if (this.props.story.properties.video !== this.props.story.properties.link) {
        link = <p className="link"><a className="btn btn-link" href={this.props.story.properties.link} target="_blank" rel="noopener noreferrer">Read more</a></p>
      }
    } else {
      link = <p className="link"><a className="btn btn-link" href={this.props.story.properties.link} target="_blank" rel="noopener noreferrer">Read more</a></p>
    }

    let tags = [];
    for (let i = 0; i < this.props.story.properties.region.length; i += 1) {
      let region = this.props.story.properties.region[i];
      let tag = <Link key={'region-' + i} to={`/?region=${region}`}><Badge key={region} pill="true" variant="light">{region}</Badge></Link>
      tags.push(tag);
    }
    for (let i = 0; i < this.props.story.properties.theme.length; i += 1) {
      let theme = this.props.story.properties.theme[i];
      let tag = <Link key={'theme-' + i} to={`/?theme=${theme}`}><Badge key={theme} pill="true" variant="light">{theme}</Badge></Link>
      tags.push(tag);
    }

    return (
      <div className="story">
        <Helmet>
          <title>{this.props.story.properties.name} | Georgia Climate Stories | Georgia Climate Project</title>

          <meta property="og:title" content={`${this.props.story.properties.name}`} />
          <meta property="og:url" content={`https://stories.georgiaclimateproject.org/${this.props.story.properties._slug}`} />
          <meta property="og:image" content={this.props.story.properties.img} />
          <meta property="og:description" content={this.props.story.properties.summary} />
          <meta property="og:site_name" content="Georgia Climate Stories" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${this.props.story.properties.name}`} />
          <meta name="twitter:description" content={this.props.story.properties.summary} />
          <meta name="twitter:image" content={this.props.story.properties.img} />

          <meta name="description" content={`this.props.story.properties.summary`} />
        </Helmet>

        <Card>
          {videoEmbedUrl !== '' &&
            <div className="embed-responsive embed-responsive-16by9">
              <iframe title={this.props.story.properties.title} className="embed-responsive-item" src={videoEmbedUrl} allowFullScreen></iframe>
            </div>
          }
          {videoEmbedUrl === '' &&
            <blockquote>
              <p className="summary">{this.props.story.properties.summary}</p>
            </blockquote>
          }

          <Card.Body>
            <p className="location">{this.props.story.properties.location.name}</p>
            <h1 className="name" dangerouslySetInnerHTML={{__html: this.props.story.properties.name}}></h1>
            <div className="meta">
              <div>
                <p className="label">Author</p>
                <p className="value">{this.props.story.properties.author}</p>
              </div>
              <div>
                <p className="label">Date</p>
                <p className="value">{this.props.story.properties.pubdate}</p>
              </div>
              <div>
                <p className="label">Publication</p>
                <p className="value">{this.props.story.properties.publication}</p>
              </div>
            </div>
            <div className="tags">
              {tags}
            </div>
            
            <p className="description" dangerouslySetInnerHTML={{__html: description}}></p>

            {link}

          </Card.Body>
        </Card>

        { playlist }
      </div>
    );
  }
}

export default Story;